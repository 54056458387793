export const vcu = {
    degree: 'Bachelors of Science',
    major: 'Computer Science',
    logo: require('../images/vcu.png')
}

export const jsarge = {
    degree: 'Associates of Science',
    major: 'Computer Science',
    logo: require('../images/reynolds.png')
}